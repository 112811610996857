import { LinkWrapper } from "@/components/link-wrapper";
import type { BoxProps } from "@project/ui";
import {
    NextLink,
    hasCloudinaryAsset,
    isCloudinaryVideo,
    CldVideo,
    CldImage,
    Box,
    AspectRatio,
    Text,
    Flex,
    Flag,
    Heading,
} from "@project/ui";
import type { PageDriverFieldsFragment } from "./__generated/page-driver.contentful.generated";
import { useMicrocopy } from "@/lib/contentful/microcopy/microcopy-context";
import { PAGMSHEvents } from "@/lib/google-tag-manager/events";

type DriverCardProps = BoxProps & {
    item: PageDriverFieldsFragment;
    isEmbedded?: boolean;
    moduleName?: string | null;
    modulePosition?: number | null;
};

const DriverCard = ({
    item,
    isEmbedded,
    moduleName,
    modulePosition,
    ...boxProps
}: DriverCardProps) => {
    const { get: getMicrocopy } = useMicrocopy();
    return (
        <Box {...boxProps} data-group>
            <LinkWrapper
                renderAs={NextLink}
                item={item}
                eventAction={PAGMSHEvents.carouselDriverCard_Click}
                clickElementName={`Card ${item?.__typename}: ${item?.title}`}
                moduleName={moduleName}
                modulePosition={modulePosition}
            >
                <Box as="figure" mb={6} rounded="medium" overflow="hidden">
                    <AspectRatio ratio="3:4" bgColor="grey100">
                        {hasCloudinaryAsset(item.driver?.asset) && (
                            <>
                                {isCloudinaryVideo(item.driver?.asset) ? (
                                    <CldVideo
                                        cloudinaryAsset={item.driver?.asset}
                                        wrapperProps={{ position: "absolute" }}
                                        loop
                                    />
                                ) : (
                                    <CldImage
                                        cloudinaryAsset={item.driver?.asset}
                                        sizes="30vw"
                                        crop={{
                                            type: "thumb",
                                            aspectRatio: "3:4",
                                        }}
                                        _groupHover={{ transform: "scale(1.02)" }}
                                        transition="transform 0.30s ease"
                                        wrapperProps={{
                                            isolation: "isolate", // Fixes Safari bug with rounded corners and scale transition
                                            rounded: "medium",
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </AspectRatio>
                </Box>
                <Text mb={2} size="caption" color="grey300" key={item?.sys.id}>
                    {getMicrocopy("global", "text.driver")}
                </Text>
                <Flex alignItems="center">
                    {item?.driver?.flag && <Flag mr={2} countryCode={item.driver?.flag} />}
                    <Heading size="headingMedium" as="h3" {...(isEmbedded && { mb: 0 })}>
                        {item.driver?.driverName}
                    </Heading>
                </Flex>
            </LinkWrapper>
        </Box>
    );
};

export { DriverCard };
