import type { BoxProps } from "@project/ui";
import {
    Box,
    AspectRatio,
    HStack,
    CldImage,
    isCloudinaryImage,
    hasCloudinaryAsset,
    Heading,
    LinkOverlay,
    NextLink,
    Text,
    LinkBox,
} from "@project/ui";
import type { PageBasicFieldsFragment } from "@/components/contentful/page-basic/__generated/page-basic.contentful.generated";
import { getHrefForPageType } from "@/common/helpers/slug";
import { PAGMSHEvents, sendPagDataToGTM } from "@/lib/google-tag-manager/events";
import { usePAGMSCardClickProperties } from "@/lib/google-tag-manager/use-pagmsh-card-click-properties";

type BasicPageCardProps = BoxProps & {
    item: PageBasicFieldsFragment;
    moduleName?: string | null;
    modulePosition?: number | null;
};

export const BasicPageCard = ({
    item,
    moduleName,
    modulePosition,
    ...boxProps
}: BasicPageCardProps) => {
    const eventData = usePAGMSCardClickProperties({
        eventAction: PAGMSHEvents.carouselBasicPageCard_Click,
        moduleName,
        modulePosition,
        item,
    });

    const handleClick = () => {
        sendPagDataToGTM(eventData);
    };

    return (
        <LinkBox onClick={handleClick} {...boxProps} data-group>
            <Box as="figure" mb={6} rounded="large" overflow="hidden">
                <AspectRatio ratio="3:4" bgColor="grey100">
                    {hasCloudinaryAsset(item.heroAsset) && isCloudinaryImage(item.heroAsset) && (
                        <CldImage
                            cloudinaryAsset={item.heroAsset}
                            sizes={"30vw"}
                            _groupHover={{ transform: "scale(1.02)" }}
                            transition="transform 0.30s ease"
                            wrapperProps={{
                                isolation: "isolate", // Fixes Safari bug with rounded corners and scale transition
                            }}
                            crop={{
                                type: "thumb",
                                aspectRatio: "3:4",
                            }}
                        />
                    )}
                </AspectRatio>
            </Box>
            {item.subtitle && (
                <HStack align="baseline" color="grey300" spacing={1} mb={2}>
                    <Text size="caption">{item.subtitle}</Text>
                </HStack>
            )}
            <Heading as="h3" size="headingMedium" fontWeight="400">
                <LinkOverlay as={NextLink} href={getHrefForPageType(item)}>
                    {item.title}
                </LinkOverlay>
            </Heading>
        </LinkBox>
    );
};
