import type { PageArticleLinkToFieldsFragment } from "@/components/contentful/page-article/__generated/page-article.contentful.generated";
import type { BoxProps } from "@project/ui";
import {
    HStack,
    CldImage,
    AspectRatio,
    hasCloudinaryAsset,
    Box,
    NextLink,
    Text,
    DateTime,
    Heading,
    CldVideoLite,
    isCloudinaryVideo,
    LinkOverlay,
    LinkBox,
} from "@project/ui";
import { Tags } from "@/components/tags";
import { getHrefForPageType } from "@/common/helpers/slug";
import { PAGMSHEvents, sendPagDataToGTM } from "@/lib/google-tag-manager/events";
import { usePAGMSCardClickProperties } from "@/lib/google-tag-manager/use-pagmsh-card-click-properties";

type ArticleCardProps = BoxProps & {
    item: PageArticleLinkToFieldsFragment;
    ratio: string;
    moduleName?: string | null;
    modulePosition?: number | null;
};

export const ArticleCard = ({
    item,
    ratio,
    moduleName,
    modulePosition,
    ...boxProps
}: ArticleCardProps) => {
    const eventData = usePAGMSCardClickProperties({
        eventAction: PAGMSHEvents.carouselArticlePageCard_Click,
        moduleName,
        modulePosition,
        item,
    });

    const handleClick = () => {
        sendPagDataToGTM(eventData);
    };

    return (
        <LinkBox onClick={handleClick} {...boxProps} data-group>
            <Box as="figure" mb={6} rounded="large" overflow="hidden">
                <AspectRatio ratio={ratio} bgColor="grey100">
                    {hasCloudinaryAsset(item.heroAsset) && (
                        <>
                            {isCloudinaryVideo(item.heroAsset) ? (
                                <CldVideoLite
                                    cloudinaryAsset={item.heroAsset}
                                    wrapperProps={{ position: "absolute" }}
                                    loop
                                />
                            ) : (
                                <CldImage
                                    cloudinaryAsset={item.heroAsset}
                                    sizes={"30vw"}
                                    _groupHover={{ transform: "scale(1.02)" }}
                                    transition="transform 0.30s ease"
                                    wrapperProps={{
                                        isolation: "isolate", // Fixes Safari bug with rounded corners and scale transition
                                    }}
                                    crop={{
                                        type: "thumb",
                                        aspectRatio: ratio,
                                    }}
                                />
                            )}
                        </>
                    )}
                </AspectRatio>
            </Box>
            <HStack align="baseline" color="grey300" spacing={1} mb={2}>
                {item.topic && <Text size="caption">{item.topic} |</Text>}
                <DateTime size="caption">{item.customFirstPublishedDate}</DateTime>
            </HStack>
            <Heading as="h3" size="headingMedium" fontWeight="400">
                <LinkOverlay as={NextLink} href={getHrefForPageType(item)}>
                    {item.title}
                </LinkOverlay>
            </Heading>
            {item.tagsCollection?.items && item.tagsCollection.items.length > 0 && (
                <Tags mt={4} tagsCollection={item.tagsCollection.items} />
            )}
        </LinkBox>
    );
};
