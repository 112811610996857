import { useAppStore } from "@/store/app-store";
import { useRouter } from "next/router";
import type { GTMEvent, PAGMSHEvents } from "./events";
import { useMemo } from "react";

type UsePAGMSCardClickPropertiesProps = {
    eventAction: (typeof PAGMSHEvents)[keyof typeof PAGMSHEvents];
    moduleName?: string | null;
    modulePosition?: number | null;
    item: {
        __typename?: string | null;
        title?: string | null;
        slug?: string | null;
    };
};

const usePAGMSCardClickProperties = ({
    eventAction,
    moduleName,
    modulePosition,
    item,
}: UsePAGMSCardClickPropertiesProps): GTMEvent => {
    const { locale } = useRouter();
    const {
        state: { pageType, pageId, pageContentTags },
    } = useAppStore();

    return useMemo(() => {
        return {
            eventAction,
            locale: locale!,
            pageExperience: {
                pageCategory: pageType,
                contentTags: pageContentTags ?? [],
            },
            context: {
                moduleName,
                modulePosition,
            },
            componentClick: {
                clickElementType: "navigation",
                clickElementId: pageId,
                clickElementName: `Card ${item?.__typename}: ${item?.title}`,
                targetUrl: `/${item?.slug}`,
                targetType: "internal",
            },
        };
    }, [
        eventAction,
        locale,
        pageType,
        pageContentTags,
        moduleName,
        modulePosition,
        pageId,
        item?.__typename,
        item?.title,
        item?.slug,
    ]);
};

export { usePAGMSCardClickProperties };
